/* Lato 300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src:
    url('/hub/fonts/Lato-Light.woff2') format('woff2'),
    url('/hub/fonts/Lato-Light.woff') format('woff');
  font-display: swap;
}

/* Lato 300 Italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src:
    url('/hub/fonts/Lato-LightItalic.woff2') format('woff2'),
    url('/hub/fonts/Lato-LightItalic.woff') format('woff');
  font-display: swap;
}

/* Lato 400 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    url('/hub/fonts/Lato-Regular.woff2') format('woff2'),
    url('/hub/fonts/Lato-Regular.woff') format('woff');
  font-display: swap;
}

/* Lato 400 Italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src:
    url('/hub/fonts/Lato-Italic.woff2') format('woff2'),
    url('/hub/fonts/Lato-Italic.woff') format('woff');
  font-display: swap;
}

/* Lato 600 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src:
    url('/hub/fonts/Lato-Semibold.woff2') format('woff2'),
    url('/hub/fonts/Lato-Semibold.woff') format('woff');
  font-display: swap;
}

/* Lato 600 Italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  src:
    url('/hub/fonts/Lato-SemiboldItalic.woff2') format('woff2'),
    url('/hub/fonts/Lato-SemiboldItalic.woff') format('woff');
  font-display: swap;
}

/* Lato 700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src:
    url('/hub/fonts/Lato-Bold.woff2') format('woff2'),
    url('/hub/fonts/Lato-Bold.woff') format('woff');
  font-display: swap;
}

/* Lato 700 Italic - latin*/
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src:
    url('/hub/fonts/Lato-BoldItalic.woff2') format('woff2'),
    url('/hub/fonts/Lato-BoldItalic.woff') format('woff');
  font-display: swap;
}
